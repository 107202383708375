define("discourse/plugins/discourse-encrypt/discourse/components/modal/generate-paper-key", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/paper-key", "discourse/plugins/discourse-encrypt/lib/protocol", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _ajax, _decorators, _discourse, _paperKey, _protocol, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    {{did-insert this.generate}}
    @closeModal={{@closeModal}}
    @title={{i18n
      (if
        @model.device
        "encrypt.generate_paper_key.title_device"
        "encrypt.generate_paper_key.title"
      )
    }}
  >
    <:body>
      <p>
        {{i18n
          (if
            @model.device
            "encrypt.generate_paper_key.instructions_device"
            "encrypt.generate_paper_key.instructions"
          )
        }}
      </p>
      <p class="paper-key">{{this.paperKey}}</p>
    </:body>
  </DModal>
  */
  {
    "id": "24vE7bjf",
    "block": "[[[8,[39,0],[[4,[38,3],[[30,0,[\"generate\"]]],null]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[[52,[30,2,[\"device\"]],\"encrypt.generate_paper_key.title_device\",\"encrypt.generate_paper_key.title\"]],null]]],[[\"body\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,1],[[52,[30,2,[\"device\"]],\"encrypt.generate_paper_key.instructions_device\",\"encrypt.generate_paper_key.instructions\"]],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"paper-key\"],[12],[1,[30,0,[\"paperKey\"]]],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"if\",\"did-insert\"]]",
    "moduleName": "discourse/plugins/discourse-encrypt/discourse/components/modal/generate-paper-key.hbs",
    "isStrictMode": false
  });
  let GeneratePaperKey = _exports.default = (_class = class GeneratePaperKey extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "paperKey", _descriptor, this);
    }
    async generate() {
      const paperKey = (0, _paperKey.generatePaperKey)();
      const label = this.args.model.device ? "device" : `paper_${paperKey.substr(0, paperKey.indexOf(" ")).toLowerCase()}`;
      const identity = await (0, _discourse.getIdentity)();
      const exported = await (0, _protocol.exportIdentity)(identity, paperKey);
      this.paperKey = paperKey;
      await (0, _ajax.ajax)("/encrypt/keys", {
        type: "PUT",
        data: {
          public: exported.public,
          private: exported.private,
          label
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "paperKey", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "generate", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "generate"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GeneratePaperKey);
});