define("discourse/plugins/discourse-encrypt/discourse/components/modal/activate-encrypt", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "I18n", "discourse/plugins/discourse-encrypt/lib/discourse", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _I18n, _discourse, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "encrypt.title"}}
    @closeModal={{this.close}}
    @flash={{this.error}}
    @flashType="error"
    class="activate-encrypt-modal"
  >
    <:body>
      <p>{{i18n "encrypt.preferences.status_enabled_but_inactive"}}</p>
  
      {{html-safe (i18n "encrypt.preferences.notice_active")}}
  
      <p>
        <label for="passphrase">
          {{i18n "encrypt.preferences.paper_key_label"}}
        </label>
        <Input
          @type="password"
          @value={{this.passphrase}}
          placeholder={{i18n "encrypt.preferences.paper_key_placeholder"}}
          id="passphrase"
          autocomplete="current-password"
          disabled={{this.inProgress}}
        />
      </p>
    </:body>
  
    <:footer>
      <DButton
        class="btn btn-primary"
        @icon="unlock"
        @label="encrypt.preferences.activate"
        @action={{this.activate}}
      />
      <DModalCancel @close={{this.close}} />
    </:footer>
  </DModal>
  */
  {
    "id": "zfS2kPal",
    "block": "[[[8,[39,0],[[24,0,\"activate-encrypt-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\",\"@flashType\"],[[28,[37,1],[\"encrypt.title\"],null],[30,0,[\"close\"]],[30,0,[\"error\"]],\"error\"]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,[28,[35,1],[\"encrypt.preferences.status_enabled_but_inactive\"],null]],[13],[1,\"\\n\\n    \"],[1,[28,[35,2],[[28,[37,1],[\"encrypt.preferences.notice_active\"],null]],null]],[1,\"\\n\\n    \"],[10,2],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"passphrase\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"encrypt.preferences.paper_key_label\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,3],[[16,\"placeholder\",[28,[37,1],[\"encrypt.preferences.paper_key_placeholder\"],null]],[24,1,\"passphrase\"],[24,\"autocomplete\",\"current-password\"],[16,\"disabled\",[30,0,[\"inProgress\"]]]],[[\"@type\",\"@value\"],[\"password\",[30,0,[\"passphrase\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn btn-primary\"]],[[\"@icon\",\"@label\",\"@action\"],[\"unlock\",\"encrypt.preferences.activate\",[30,0,[\"activate\"]]]],null],[1,\"\\n    \"],[8,[39,5],null,[[\"@close\"],[[30,0,[\"close\"]]]],null],[1,\"\\n  \"]],[]]]]]],[],false,[\"d-modal\",\"i18n\",\"html-safe\",\"input\",\"d-button\",\"d-modal-cancel\"]]",
    "moduleName": "discourse/plugins/discourse-encrypt/discourse/components/modal/activate-encrypt.hbs",
    "isStrictMode": false
  });
  let ActivateEncrypt = _exports.default = (_class = class ActivateEncrypt extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "appEvents", _descriptor2, this);
      _initializerDefineProperty(this, "encryptWidgetStore", _descriptor3, this);
      _initializerDefineProperty(this, "inProgress", _descriptor4, this);
      _initializerDefineProperty(this, "passphrase", _descriptor5, this);
      _initializerDefineProperty(this, "error", _descriptor6, this);
    }
    async activate() {
      this.inProgress = true;
      try {
        await (0, _discourse.activateEncrypt)(this.currentUser, this.passphrase);
        this.appEvents.trigger("encrypt:status-changed");
        for (const widget of this.encryptWidgetStore.widgets) {
          widget.state.encryptState = "decrypting";
          widget.scheduleRerender();
        }
        this.encryptWidgetStore.reset();
        this.args.closeModal();
      } catch (e) {
        this.error = _I18n.default.t("encrypt.preferences.paper_key_invalid");
      } finally {
        this.inProgress = false;
      }
    }
    close() {
      for (const widget of this.encryptWidgetStore.widgets) {
        widget.state.encryptState = "error";
        widget.state.error = _I18n.default.t("encrypt.preferences.status_enabled_but_inactive");
        widget.scheduleRerender();
      }
      this.encryptWidgetStore.reset();
      this.args.closeModal();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "encryptWidgetStore", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "inProgress", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "passphrase", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "activate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "activate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivateEncrypt);
});